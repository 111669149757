import $ from 'sbf-design-system/js/jquery';
import 'sbf-design-system/js/lib/jquery.customSelect';

function input_is_empty ($input) {
  const $select = $input.find('select');
  const $csu_state_input_element = $input.find('.csu-state-input-element');

  if ($csu_state_input_element.is(':visible') && $csu_state_input_element.val().trim() === '') {
    return true;
  }

  if ($input.find('input, textarea').not('.csu-state-input-element').filter(function () {
    return $(this).val().trim() === '';
  }).length > 0) {
    return true;
  }

  if ($select.length) {
    const $selected = $select.eq(0).find(':selected');
    if ($selected.prop('disabled') || $selected.val().trim() === '') {
      return true;
    }
  }

  return false;
}

function initialize () {
  $('.inline-input').each(function () {
    const $con = $(this);
    if (input_is_empty($con)) {
      $con.addClass('empty');
    } else {
      $con.removeClass('empty');
    }
  });

  $('.inline-input select').change(function () {
    const $con = $(this).parents('.inline-input');
    if ($(this).hasClass('customSelectDisabledOption') || !$(this).val()) {
      $con.addClass('empty');
    } else {
      $con.removeClass('empty');
    }
  });

  $('.inline-input').on('change keyup', 'input, textarea', function () {
    const $con = $(this).parents('.inline-input');
    if ($(this).val() !== '') {
      $con.removeClass('empty');
    } else {
      $con.addClass('empty');
    }
  });
}

initialize();

export default initialize;
